import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

const useGeoLocation = (locationAccess: boolean) => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: '', lng: '' },
    locale: '',
    countryCode: '',
    error: {},
  })

  const usCountryList = ['US', 'CA', 'PR']
  const europeCountryList = [
    'DE',
    'NL',
    'GB',
    'BE',
    'IE',
    'LU',
    'NO',
    'PL',
    'ES',
    'SE',
    'DK',
  ]

  // Free GEOCODE API client to get country code
  // Reference Link: https://www.bigdatacloud.com/docs/api/free-reverse-geocode-to-city-api
  const REVERSE_GEOCODE_CLIENT: string =
    'https://api.bigdatacloud.net/data/reverse-geocode-client'

  const onSuccess = async (location: {
    coords: { latitude: any; longitude: any }
  }) => {
    if (locationAccess) {
      let res: AxiosResponse
      let locale: string = 'en-us'
      let countryCode
      try {
        res = await axios.get(
          `${REVERSE_GEOCODE_CLIENT}?latitude=${location.coords.latitude}&longitude=${location.coords.longitude}&localityLanguage=en`
        )

        if (res && res.data && Object.keys(res.data).length) {
          countryCode = res.data.countryCode
          if (usCountryList.includes(countryCode)) {
            locale = 'en-us'
          } else if (europeCountryList.includes(countryCode)) {
            locale = 'en-gb'
          } else {
            locale = 'en-us'
            countryCode = 'US'
          }
        }
      } catch {
        console.log('Error connecting to Geocode API')
      }

      setLocation({
        loaded: true,
        coordinates: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        },
        locale,
        countryCode,
        error: {}, 
      })
    }
  }

  const onError = (error: { code: any; message: any }) => {
    setLocation({
      loaded: true,
      coordinates: { lat: '', lng: '' },
      locale: 'en-us',
      countryCode: 'US',
      error: {
        code: error.code,
        message: error.message,
      },
    })
  }

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError({
        code: 0,
        message: 'Geolocation not supported',
      })
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError)
  }, [locationAccess])

  return location
}

export default useGeoLocation
